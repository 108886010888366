<template>
<div>
  <div class="row justify-content-center py-16 px-8 py-md-16 px-md-0 bgi-size-cover bgi-position-center" style="background: #2a84a6 url('')">
    <div class="col-md-6 text-white">
      <div class="d-flex ">
        <div class="d-flex flex-column px-0 order-2 order-md-1">
            <h2 class="display-3 font-weight-boldest order-1" v-html="$t('TERMS.TITOLO')"></h2>
            <h3 class="mb-10 order-2" v-html="$t('TERMS.SOTTOTITOLO')"></h3>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!getTermsConditions" class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-8 col-xl-6">
      <div class="row align-items-center mt-5">
        <h3 class="subtitle" v-html="$t('TERMS.OGGETTO')"></h3>
        <p class="lead" v-html="$t('TERMS.OGGETTODESCR1')">

        </p>

        <h3 class="subtitle" v-html="$t('TERMS.REGISTRAZIONE')"></h3>
        <p class="lead" v-html="$t('TERMS.REGISTRAZIONEDESCR1')"></p>

        <h3 class="subtitle" v-html="$t('TERMS.ATTIVITA')"></h3>
        <p class="lead" v-html="$t('TERMS.ATTIVITADESCR1')" >

        </p>

        <h3 class="subtitle" v-html="$t('TERMS.RESPONSABILITA')"></h3>

        <p class="lead" v-html="$t('TERMS.RESPONSABILITADESCR1')"></p>
        <ul v-html="$t('TERMS.RESPONSABILITADESCR2')" class="ml-7">

        </ul>

        <h3 class="subtitle" v-html="$t('TERMS.UTILIZZO')"></h3>
        <p class="lead" v-html="$t('TERMS.UTILIZZODESCR1')">
           </p>
        <ul v-html="$t('TERMS.UTILIZZODESCR2')" class="ml-7">


        </ul>
        <p class="lead" v-html="$t('TERMS.UTILIZZODESCR3')"></p>

        <h3 class="subtitle" v-html="$t('TERMS.LIMITAZIONI')"></h3>

        <p class="lead" v-html="$t('TERMS.LIMITAZIONIDESCR1')"></p>

        <h3 class="subtitle" v-html="$t('TERMS.COMUNICAZIONI')"></h3>
        <p class="lead" v-html="$t('TERMS.COMUNICAZIONIDESCR1')"></p>
        <h3 class="subtitle" v-html="$t('TERMS.MODIFICA')"></h3>
        <p class="lead" v-html="$t('TERMS.MODIFICADESCR1')"></p>

      </div>
    </div>
  </div>
  <div v-else class="row justify-content-center py-8 px-8 py-md-20 px-md-0 bg-default">
    <div class="col-lg-8 col-xl-6">
      <div class="row align-items-center mt-5" v-html="termsConditions">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "terms",
  data() {
    return {
      termsConditions: "",
    }
  },
  computed: {
    getTermsConditions() {
      return this.termsConditions != '';
    },
    ...mapGetters([
      "getOrganization"
    ]),
  },
  mounted() {
    this.termsConditions = this.getOrganization?.data?.termsConditions ?? '';
  },
};
</script>
